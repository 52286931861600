<template>
	<b-modal
		ref="productReviewModal"
		no-close-on-backdrop
		no-close-on-esc
		header-class="mx-auto w-100 bg-dark text-white"
		size="md"
		body-class="p-0"
		centered
		@hide="close()">
		<template #modal-footer>
			<b-button
				@click="close()">
				{{ translate('cancel') }}
			</b-button>
			<b-button
				variant="primary"
				:disabled="loading || !review.report_reason"
				@click="confirm()">
				<i
					v-if="loading"
					class="fa fa-fw fa-spinner fa-pulse" />
				{{ translate('submit') }}
			</b-button>
		</template>
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate('report_review') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close()">
				×
			</button>
		</template>
		<article
			class="m-3 p-2"
			style="background-color: #F8F8F8; border-radius: 8px">
			<h5
				class="m-0"
				style="font-weight: unset">
				{{ review.attributes.user.shortened_name }}
			</h5>
			<div class="d-flex align-items-center flex-wrap">
				<div class="d-flex justify-content-start">
					<b-rating
						:value="review.attributes.rating"
						variant="primary"
						style="padding-left: 0; padding-top: 0; padding-bottom: 0; background-color: transparent"
						inline
						no-border
						show-value-max
						show-value
						readonly />
				</div>
				<span class="text-muted text-medium">
					{{
						review.attributes.created_at.date
							? $moment(review.attributes.created_at.date).format(dateFormat)
							: $moment(review.attributes.created_at).format(dateFormat)
					}}
				</span>
			</div>
			<h6>
				{{ review.attributes.title }}
			</h6>
			<p class="m-0">
				{{ review.attributes.comment }}
			</p>
			<p
				v-if="review.attributes.edited"
				class="m-0 text-muted text-medium"
				style="text-transform: lowercase;">
				({{ translate('edited') }})
			</p>
		</article>
		<hr class="my-2 mx-3">
		<b-form class="mx-3 mt-3">
			<p>{{ translate('report_review_description') }}</p>
			<b-form-group>
				<label for="report_reason">{{ translate('report_reason') }}:</label>
				<b-form-textarea
					id="report_reason"
					v-model="review.report_reason"
					style="color: black"
					rows="3"
					max-rows="6"
					:placeholder="translate('report_reason_placeholder')" />
				<template v-if="hasError('report_reason')">
					<span
						v-for="error in errors['report_reason']"
						:key="error"
						class="custom-invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>

import { ProductReviews as ProductReviewsMessages } from '@/translations';
import ProductReviews from '@/util/ProductReviews';
import { CONFLICT, UNPROCESSABLE } from '@/settings/Errors';
import { MMMDY_FORMAT } from '@/settings/Dates';

export default {
	name: 'ReportProductReviewModal',
	messages: [ProductReviewsMessages],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		reviewInfo: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			productReview: new ProductReviews(),
			alert: new this.$Alert(),
			review: { ...this.reviewInfo },
			errors: {},
			dateFormat: MMMDY_FORMAT,
		};
	},
	computed: {
		loading() {
			return this.productReview.data.loading;
		},
	},
	watch: {
		show() {
			if (this.show) {
				this.open();
			} else {
				this.close();
			}
		},
		reviewInfo: {
			handler() {
				this.review = { ...this.reviewInfo };
			},
			immediate: true,
			deep: true,
		},
		'review.report_reason': {
			handler() {
				this.$delete(this.errors, 'report_reason');
			},
		},
	},
	methods: {
		open() {
			this.review = { ...this.reviewInfo };
			this.$refs.productReviewModal.show();
			this.$emit('update:show', true);
		},
		close() {
			this.errors = {};
			this.$refs.productReviewModal.hide();
			this.$emit('update:show', false);
		},
		confirm() {
			this.create();
		},
		create() {
			this.productReview.reportProductReview(this.review.id, this.review.report_reason).then(() => {
				this.alert.toast('success', this.translate('successfully_submitted'));
				this.$emit('created');
				this.close();
			}).catch(() => {
				const { status, errors } = this.productReview.errors;
				if (UNPROCESSABLE.includes(status)) {
					// eslint-disable-next-line no-restricted-syntax
					for (const error of Object.keys(errors)) {
						this.$set(this.errors, error, errors[error]);
					}
				} else if (CONFLICT.includes(status)) {
					this.alert.toast('error', this.translate('already_reported'));
					this.close();
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
				this.$emit('error');
			});
		},
		hasError(field) {
			return typeof this.errors[field] !== 'undefined';
		},
		cancel() {
			this.close();
			this.$emit('cancel');
		},
	},
};
</script>
