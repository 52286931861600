<template>
	<article
		class="my-3">
		<h5
			class="m-0"
			style="font-weight: unset">
			{{ review.attributes.user.shortened_name }}
		</h5>
		<div class="d-flex align-items-center flex-wrap">
			<div class="d-flex justify-content-start">
				<b-rating
					:value="review.attributes.rating"
					variant="primary"
					style="padding-left: 0; padding-top: 0; padding-bottom: 0"
					color="var(--theme-primary-color)"
					inline
					no-border
					show-value-max
					show-value
					readonly />
			</div>
			<span class="text-muted text-medium">
				{{
					review.attributes.created_at.date
						? $moment(review.attributes.created_at.date).format(dateFormat)
						: $moment(review.attributes.created_at).format(dateFormat)
				}}
			</span>
		</div>
		<h6>
			{{ review.attributes.title }}
		</h6>
		<p class="m-0">
			{{ review.attributes.comment }}
		</p>
		<p
			v-if="review.attributes.edited"
			class="m-0 text-muted text-medium"
			style="text-transform: lowercase;">
			({{ translate('edited') }})
		</p>
		<div
			v-if="$user.auth()"
			class="mt-2">
			<p class="text-medium d-inline mr-2 text-muted">
				{{ translate('was_this_review_helpful') }}
			</p>
			<b-icon-hand-thumbs-up
				class="custom-thumbs mr-2"
				:class="{ 'text-muted': review.attributes.helpful !== 1 }"
				:style="review.attributes.helpful === 1 ? `color: var(--theme-primary-color);` : ''"
				flip-h
				font-scale="1.5"
				:title="translate('mark_as_helpful')"
				@click="markAsHelpful(1)" />
			<b-icon-hand-thumbs-down
				:class="{ 'text-muted': review.attributes.helpful !== 0 }"
				class="custom-thumbs"
				:style="review.attributes.helpful === 0 ? `color: var(--theme-primary-color);` : ''"
				font-scale="1.5"
				:title="translate('mark_as_not_helpful')"
				@click="markAsHelpful(0)" />
			<div
				class="d-inline"
				style="border-left: 1px solid #dee2e6; margin: 0 10px;" />
			<button
				:disabled="review.attributes.reported"
				class="btn btn-link d-inline p-0 text-medium text-muted"
				@click="report()">
				{{ translate('report') }}
			</button>
		</div>
		<report-product-review-modal
			:show.sync="showReportModal"
			:review-info="review" />
	</article>
</template>
<script>
import {
	BIconHandThumbsUp, BIconHandThumbsDown,
} from 'bootstrap-vue';
import { MMMDY_FORMAT } from '@/settings/Dates';
import { ProductReviews as ProductReviewsMessages } from '@/translations';
import ProductReviews from '@/util/ProductReviews';
import ReportProductReviewModal from '@/components/ProductReview/ReportProductReviewModal.vue';

export default {
	name: 'ProductReview',
	messages: [ProductReviewsMessages],
	components: {
		BIconHandThumbsUp,
		BIconHandThumbsDown,
		ReportProductReviewModal,
	},
	props: {
		review: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			productReviews: new ProductReviews(),
			dateFormat: MMMDY_FORMAT,
			showReportModal: false,
		};
	},
	methods: {
		markAsHelpful(helpful) {
			if (this.review.attributes.helpful === helpful) {
				return;
			}
			this.productReviews.markAsHelpful(this.review.id, helpful).then(() => {
				this.review.attributes.helpful = helpful;
			});
		},
		report() {
			this.showReportModal = true;
		},
	},
};
</script>

<style scoped>
p, span {
	font-weight: normal;
}
h6 {
	font-weight: bold;
}

.custom-thumbs {
	transform: scale(1);
	transition: transform 0.2s ease-in-out;
	cursor: pointer;
}

.custom-thumbs:hover {
	transform: scale(1.3);
}
</style>
