<template>
	<div>
		<option-select
			v-for="(item, index) in multipleOptions"
			:key="index"
			:select-first="false"
			:variant="item.variant"
			:title="item.title"
			:options="item.options"
			:initial-selected-option="selection[index]"
			@update:selectedOption="updateSelection(index, $event)">
			<div
				v-if="showOutOfStock(item.options)"
				class="row">
				<div class="col-md-7 col-sm-12 col-xs-12 align-self-center">
					<button
						class="mb-0 btn btn-block btn-disabled disabled">
						{{ translate(`out_of_stock`) }}
					</button>
				</div>
			</div>
		</option-select>
	</div>
</template>
<script>
import { Products } from '@/translations';
import OptionSelect from '@/components/OptionSelect';

export default {
	name: 'MultipleOptionSelect',
	messages: [Products],
	components: { OptionSelect },
	props: {
		multipleOptions: {
			type: Array,
			required: true,
			default: () => [],
		},
		requiredSelect: {
			type: Number,
			required: true,
		},
		mandatorySelection: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selection: [],
			queue: [],
			mandatoryIndex: [],
		};
	},
	mounted() {
		// Initialize spaces
		for (let i = 0; i < this.multipleOptions.length; i += 1) {
			this.selection.push(undefined);
		}

		if (this.mandatorySelection.length) {
			this.mandatorySelection.forEach((item) => {
				const index = this.multipleOptions.findIndex((option) => option.sku === item);
				if (index >= 0) {
					const product = this.multipleOptions[index];
					const { value } = product.options[0];
					this.selection[index] = value;
					this.mandatoryIndex.push(index);
					this.queue.push(index);
				}
			});
		}
	},
	methods: {
		updateSelection(index, value) {
			// Set code value in index
			this.selection[index] = value;
			const selected = this.selection.filter((item) => item !== undefined).length;

			// Enqueue only when option is selected (when value equals undefined it means option is not selected)
			if (value !== undefined && this.queue.findIndex((item) => item === index) === -1) {
				this.queue.push(index);
			}

			// If the selected quantity is greater than allowed, deselect oldest option
			if (selected > this.requiredSelect) {
				const cleanQueue = this.queue.filter((item) => !this.mandatoryIndex.includes(item));
				this.selection[cleanQueue.shift()] = undefined;
				this.queue = this.mandatoryIndex.concat(cleanQueue);
			}

			this.selection = [...this.selection];
			this.$emit('update:selection', this.selection);
		},
		showOutOfStock(options) {
			return options.filter((option) => option.outOfStock).some((option) => this.selection.includes(option.value));
		},
	},
};
</script>
